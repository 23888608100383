export const EXPERIMENT_NAMES = Object.freeze({
  PRICING_PILATES_WEEKLY_NEW_CHECKOUT: 'pricing-pilates-weekly-new-checkout',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_DIFFERENT_TITLE_US: 'pilates-28-day-challenge-checkout-different-title-us',
  PILATES_CHALLENGE_AND_NUTRITION_YOGA_PRICE_INCREASE_AU: 'pilates-challenge-and-nutrition-yoga-price-increase-au',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_REMOVE_PAYPAL_OPTION_IN_BR:
    'pilates-28-day-challenge-checkout-remove-paypal-option-in-br',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_MONEY_BACK_GUARANTEE: 'pilates-28-day-challenge-checkout-money-back-guarantee',
  PILATES_CHALLENGE_MULTIPLE_PLAN_SELECTOR: 'pilates-challenge-multiple-plan-selector',
  YOGA_FULL_BODY_BEFORE_AFTER_IMAGES: 'yoga-full-body-before-after-images',
  QUIZ_UPDATE_GOAL_STEP_PILATES: 'quiz-update-goal-step-pilates',
  YOGA_28_DAY_CHALLENGE_REVAMP: 'yoga-28-day-challenge-revamp',
  PILATES_28_DAY_CHALLENGE_BMI_STEP: 'pilates-28-day-challenge-bmi-step',
  PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER: 'pilates-28-day-challenge-lp-no-footer',
});


import { mapActions, mapGetters } from 'vuex';

import { REGIONS } from '@/modules/region/domain/region';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: 'QuizProgress',
  computed: {
    ...mapGetters('quiz', [
      'getQuizTotalLength',
      'getQuizCurrentIndex',
      'getQuizVariant',
      'getAnswerById',
      'getPrevStepId',
      'getQuizList',
      'getQuizStepFrom',
      'getQuizStepTo',
      'getQuizReferrerLink',
      'getProductType',
    ]),
    percentageValue() {
      return `${((100 / this.getQuizTotalLength) * (this.getQuizCurrentIndex + 1)).toFixed(2)}%`;
    },
    showProgressBar() {
      if (
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.shouldReplicateWeightLossMonthlyTour ||
        this.isJoinRegionAndPilatesTour ||
        this.isYogaTour
      ) {
        return false;
      }
      return this.$t('quizLayout')?.showProgressBar;
    },
    isJoinRegionAndPilatesTour() {
      const isPilatesTour = [
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        QUIZ_VARIANTS.PILATES_NOW,
      ].includes(this.getQuizVariant);

      return isPilatesTour && this.$countryCode === REGIONS.join;
    },
  },
  methods: {
    ...mapActions('quiz', ['setQuizCurrentIndexAction', 'setQuizStepFrom', 'setQuizStepTo']),
    scrollToTop() {
      if (window) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    goBackHandler() {
      if (this.getQuizCurrentIndex === 0) {
        window.location.href = this.getQuizReferrerLink || this.$getUrl('home');
        return;
      }
      const currentQuiz = this.getQuizList[this.getQuizCurrentIndex];
      const previousQuizIndex = this.getQuizList.findIndex((quiz) => quiz.id === currentQuiz.goBackStep);
      const eventIndex = previousQuizIndex === -1 ? this.getQuizCurrentIndex - 1 : previousQuizIndex;

      this.scrollToTop();
      this.$nextTick(() => {
        this.setQuizCurrentIndexAction(eventIndex);
      });
    },
  },
};

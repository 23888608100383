/**
 * This function changes the language of the app depending on:
 *   - `lang` query parameter
 *   - `accept-language` request header
 *
 * If `lang` query parameter is present, it will be the one used, no matter what is coming inside the `accept-language`
 * request header.
 *
 * In case `lang` query parameter doesn't exist, `accept-language` header will be parsed and extracted a list of
 * languages from it. That list will be compared to the list of locales that the app has in the `i18n` plugin, and if
 * found any compatible, will load it.
 *
 * This is an example of `accept-language` request header:
 *   accept-language: es,en;q=0.9,en-GB;q=0.8,en-US;q=0.7
 */
import { COOKIES } from '@/services/constants';

const localeCache = {};

export default async function ({ app, $cookies, route }) {
  try {
    const { $countryCode } = app;
    const lang = route.query.lang || $cookies.get(COOKIES.lang) || '';

    if (lang) {
      $cookies.set(COOKIES.lang, lang, {
        maxAge: 60 * 60 * 24 * 7,
        path: '/',
        secure: process.env.NODE_ENV !== 'development',
      });
    }

    const acceptLanguage = null; // TODO: remove this when deploying browser lang detection
    const formattedAcceptLanguage = lang
      ? `${lang}-${$countryCode.toLowerCase()}`
      : acceptLanguage
        ? `${
            acceptLanguage.length === 2 ? acceptLanguage : acceptLanguage.split('-').shift().toLowerCase()
          }-${$countryCode.toLowerCase()}`
        : `en-${$countryCode.toLowerCase()}`;

    if (formattedAcceptLanguage) {
      const locale =
        app.i18n.locales.find((locale) => locale.acceptedLanguage === formattedAcceptLanguage) ||
        app.i18n.locales.find((locale) => locale.acceptedLanguage === `en-${$countryCode.toLowerCase()}`) ||
        app.i18n.locales.find((locale) => locale.acceptedLanguage === 'en-us');

      if (locale) {
        try {
          let foundLocale = localeCache[locale.file];

          if (!foundLocale) {
            foundLocale = await import(`../locales/${locale.file}`).then((module) => module.default);
            localeCache[locale.file] = foundLocale;

            if (!foundLocale) {
              throw new Error(`Invalid locale ${locale.file}`);
            }
          }

          app.i18n.locale = locale.code;
          app.i18n.defaultLocale = locale.code;
          await app.i18n.setLocale(locale.code);
          await app.i18n.setLocaleMessage(locale.code, foundLocale);
          console.log('Language switched to', locale.code);
        } catch (importError) {
          console.error('Error using a not valid language', importError);
        }
      }
    }
  } catch (globalError) {
    console.error('Something went wrong while parsing language', globalError);
  }
}

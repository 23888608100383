
import { mapGetters } from 'vuex';

import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: 'CssCustomizer',
  computed: {
    ...mapGetters('quiz', ['getQuizVariant']),
    cssText() {
      switch (this.getQuizVariant) {
        case QUIZ_VARIANTS.PILATES_NEW_CHECKOUT:
        case QUIZ_VARIANTS.WALL_PILATES:
        case QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE:
        case QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY:
        case QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES:
        case QUIZ_VARIANTS.PILATES_NOW:
          return `
            :root {
              --c-primary: #007BBD !important;
              --c-primary-dark: #007BBD !important;
              --c-primary-light: #3B99CC !important;
              --border-radius: 6.25rem !important;
              --c-disabled: #A4CDE2 !important;
              --c-gray-200: #E9F0F4 !important;
            }

            .balance-text {
              text-transform: initial !important;
            }

            .content__heading {
              text-transform: initial !important;
            }
          `;
        default:
          return '';
      }
    },
  },
};

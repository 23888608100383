
import { mapGetters } from 'vuex';

import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';
import { Helpers } from '@/services/helpers';

export default {
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    sliders: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: String,
      default: PROJECT_BRANDS.reverse,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noFooterAndDisclaimer: {
      type: Boolean,
      default: false,
    },
    noFooterAndMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('quiz', ['getHeaderStatus', 'getProductType']),
    ...mapGetters('brand', ['isF040Brand']),
    ...mapGetters('common', ['getIsMembersArea']),

    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    loggedin() {
      return Helpers.loggedin();
    },
    newMenuItem() {
      return this.noFooter && this.noFooterAndDisclaimer;
    },
    isBodyNutritionButton() {
      return (
        this.isBodyNutritionTour ||
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.isNutritionKetoTour ||
        this.shouldReplicateWeightLossMonthlyTour
      );
    },
    homeUrl() {
      const variantsMap = {
        [QUIZ_VARIANTS.DEFAULT]: 'home',
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'homePilates',
        [QUIZ_VARIANTS.WALL_PILATES]: 'homeFitOver40',
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'homePilates',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: 'homePilatesChairChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: 'homePilatesFlatBelly',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'homePilatesChallengeSomatic',
        [QUIZ_VARIANTS.PILATES_NOW]: 'homePilatesNow',
        [QUIZ_VARIANTS.BODY_NUTRITION]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'homeNutrition28DayNoSugarChallenge',
        [QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'homeNutrition28DayWeightLossChallenge',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE]: 'homeNutritionMonthlyCortisolCleanse',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_START]: 'homeNutritionWeeklyStart',
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_CORTISOL_CLEANSE]: 'homeNutritionWeeklyCortisolCleanse',
        [QUIZ_VARIANTS.NUTRITION_KETO]: 'homeNutritionKeto',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: 'homeNutritionKetoMeal',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: 'homeNutritionKetoWeeklyMeal',
        [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.MACRO_DIET]: 'homeMacroDietForWomen',
        [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homeYogaChallengeGenericOthers',
        [QUIZ_VARIANTS.SOMATIC_YOGA]: 'homeSomaticYoga',
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: 'homeSomaticExercises',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: 'homeNutritionMonthlyGenericOthers',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: 'homePilatesChallengeGenericUs',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homePilatesChallengeGenericOthers',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: 'homeYogaChallengeGenericUs',
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_MEDITERRANEAN_DIET]: 'homeNutritionWeeklyMediterraneanDiet',
        [QUIZ_VARIANTS.WALKING_INDOOR]: 'homeWalkingIndoor',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR]: 'homeYogaChallengeSenior',
        [QUIZ_VARIANTS.CHAIR_YOGA_V1]: 'homeChairYogaV1',
        [QUIZ_VARIANTS.YOGA_LAZY]: 'homeYogaLazy',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[QUIZ_VARIANTS.DEFAULT];
    },
    getHamBurgerIcon() {
      return [
        QUIZ_VARIANTS.BODY_NUTRITION,
        QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE,
        QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US,
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        QUIZ_VARIANTS.MACRO_DIET,
        QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        QUIZ_VARIANTS.METABOLIC_RENEWAL,
        QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
        QUIZ_VARIANTS.SOMATIC_YOGA,
        QUIZ_VARIANTS.SOMATIC_EXERCISES,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.NUTRITION_WEEKLY_MEDITERRANEAN_DIET,
        QUIZ_VARIANTS.WALKING_INDOOR,
        QUIZ_VARIANTS.CHAIR_YOGA_V1,
      ].includes(this.getQuizVariant)
        ? 'icon-hamburger-menu'
        : 'icon-hamburger';
    },
    coloredHeader() {
      return [
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.WALKING_INDOOR,
      ].includes(this.getQuizVariant);
    },
    // To do: this is a V1 iteration to have something functional. We need to find a better way to handle multibranding.
    aboutUsUrl() {
      return this.isF040Brand ? this.$getUrl('aboutUs') : this.$getUrl('our-cause');
    },
    helpCenterUrl() {
      return this.isF040Brand ? `https://help.fitover40.health/${this.getLanguage}/` : this.$getHelpCenterUrl();
    },
    homeLogoUrl() {
      return this.isF040Brand ? this.$getUrl('homeFitOver40') : this.$getUrl(this.homeUrl);
    },
    hamburgerAriaLabel() {
      return this.isOpen ? this.$t('mainHeader.hamburgerMenu.closeMenu') : this.$t('mainHeader.hamburgerMenu.openMenu');
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  methods: {
    navigationHandler() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      Helpers.logout();
      window.location.href = this.$getUrl('home');
    },
    handleDefaultState() {
      this.$router.push('/quiz/start');
    },
  },
};

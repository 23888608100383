
import { mapGetters } from 'vuex';

import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';

import { getFooterLinksByBrand, getPrivacyAndTermsByBrand } from './main-footer.service';

export default {
  props: {
    hasDisclaimer: {
      type: Boolean,
      default: true,
    },
    brand: {
      type: String,
      default: PROJECT_BRANDS.reverse,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noFooterAndDisclaimer: {
      type: Boolean,
      default: false,
    },
    noFooterAndMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters('footer', ['getFooterStatus']),
    ...mapGetters('quiz', ['getHeaderStatus']),

    getFooterText() {
      return this.$t('landingPage.footer.text');
    },
    isReverseHealth() {
      return this.brand === PROJECT_BRANDS.reverse;
    },
    shouldShowFooter() {
      return !this.noFooter && !this.noFooterAndDisclaimer && !this.noFooterAndMenu;
    },
    footerLinks() {
      return getFooterLinksByBrand(this.isReverseHealth, this.helpCenterUrl);
    },
    privacyAndTermsLinks() {
      return getPrivacyAndTermsByBrand({ isRh: this.isReverseHealth, tosCb: this.tosUrl, ppUrlCb: this.ppUrl });
    },
  },
  methods: {
    helpCenterUrl() {
      return this.brand === PROJECT_BRANDS.fitover40
        ? `https://help.fitover40.health/${this.getLanguage}/`
        : this.$getHelpCenterUrl();
    },
  },
};

import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER,
  includedRoutes: ['/tour/pilates-28-day-challenge', '/tour/pilates-28-day-challenge/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3}`,
    },
  ],
};

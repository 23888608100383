
import { mapActions } from 'vuex';

import { AUTH_LOGIN } from '@/components/auth/helpers/auth-component-names';
import SvgContainer from '@/components/core/svg-container/svg-container';
import CssCustomizer from '@/components/static/css-customizer/css-customizer';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: 'LayoutPayment',
  components: {
    SvgContainer,
    CssCustomizer,
  },
  head() {
    const pilatesStyles = {
      ':root': {
        '--c-primary': '#007BBD !important',
        '--c-primary-dark': '#007BBD !important',
        '--c-primary-light': '#3B99CC !important',
        '--border-radius': '6.25rem !important',
        '--c-disabled': '#A4CDE2 !important',
        '--c-gray-200': '#E9F0F4 !important',
      },
      '.balance-text': {
        'text-transform': 'initial !important',
      },
      '.content__heading': {
        'text-transform': 'initial !important',
      },
    };

    const traditionalStyles = {
      ':root': {
        '--c-primary': '#B82F60 !important',
        '--c-primary-dark': '#B82F60 !important',
        '--border-radius': '6.25rem !important',
      },
      '.balance-text': {
        'text-transform': 'initial !important',
      },
      '.content__heading': {
        'text-transform': 'initial !important',
      },
      '.quiz-measurement': {
        '--c-tab-active': '#299DE0 !important',
      },
      '.congratulations .quiz-achievable-weight__value': {
        color: '#B82F60 !important',
      },
      '.discount-timer-payment': {
        '--c-timer-separator': '#D691AA !important',
      },
      '.quiz-report-item': {
        '--box-background': '#F7FBF8 !important',
      },
    };

    const ketoStyles = {
      ':root': {
        '--c-primary': '#3B7841 !important',
        '--c-primary-dark': '#3B7841 !important',
        '--c-primary-light': '#3B99CC !important',
        '--c-primary-light-1': '#4D8A53 !important',
        '--c-disabled': '#ADC6AF !important',
        '--border-radius': '6.25rem !important',
      },
      '.payment-options-new': {
        '--c-payment-card-inactive': '#C6CDE4 !important',
        '--c-money-back-anchor': '#3B7841 !important',
      },
      '.discount-timer': {
        'background-color': '#007BBD !important',
      },
      '.quiz-report-item': {
        '--box-background': '#F4F7FE !important',
      },
      '.quiz-report-item .quiz-report-item__icon': {
        'background-color': '#007BBD !important',
      },
      '.payment-options__reviews-list': {
        '--c-reviews-box-background': '#F4F7FE !important',
      },
      '.balance-text': {
        'text-transform': 'initial !important',
      },
      '.content__heading': {
        'text-transform': 'initial !important',
      },
      '.quiz-measurement': {
        '--c-tab-active': '#299DE0 !important',
      },
      '.congratulations .quiz-achievable-weight__value': {
        color: '#3B7841 !important',
      },
    };

    const yogaStyles = {
      ':root': {
        '--c-primary': 'var(--yoga-primary) !important',
        '--c-primary-dark': 'var(--yoga-primary-dark) !important',
        '--c-primary-light': 'var(--yoga-primary-light) !important',
        '--border-radius': '6.25rem !important',
      },
      '.balance-text': {
        'text-transform': 'initial !important',
      },
      '.main-header': {
        '--c-background-color': 'var(--yoga-primary) !important',
      },
      '.quiz-measurement': {
        '--c-tab-active': '#5695C6 !important',
      },
      '.button': {
        'box-shadow': 'none !important',
      },
      '.discount-timer': {
        'background-color': '#5795C6 !important',
      },
      '.quiz-report-item': {
        'background-color': '#F7FBF8 !important',
      },
    };

    const somaticYoga = {
      ':root': {
        '--c-primary': 'var(--somatic-yoga-primary) !important',
        '--c-primary-dark': 'var(--somatic-yoga-primary-dark) !important',
        '--c-primary-light': 'var(--somatic-yoga-primary-light) !important',
        '--border-radius': '6.25rem !important',
      },
      '.balance-text': {
        'text-transform': 'initial !important',
      },
      '.main-header': {
        '--c-background-color': 'var(--somatic-yoga-primary) !important',
      },
      '.quiz-measurement': {
        '--c-tab-active': '#5695C6 !important',
      },
      '.button': {
        'box-shadow': 'none !important',
      },
      '.discount-timer': {
        'background-color': '#5795C6 !important',
      },
      '.quiz-report-item': {
        'background-color': '#F7FBF8 !important',
      },
    };

    const fitOver40Styles = {
      '.upsell-progress': {
        '--c-slider': '#8578CD !important',
        '--c-handle': '#8578CD !important',
        '--c-handle-background': '#F0F1F6 !important',
        '.upsell-progress__handle-text': {
          'font-family': 'var(--font-neue-haas) !important',
        },
        '.upsell-progress__bottom-text': {
          'font-family': 'var(--font-neue-haas) !important',
        },
      },
    };

    const fitnessWalkingStyles = {
      ':root': {
        '--c-primary': 'var(--fitness-walking-primary) !important',
        '--c-primary-dark': 'var(--fitness-walking-primary-dark) !important',
        '--c-primary-light': 'var(--fitness-walking-primary-light) !important',
        '--border-radius': '6.25rem !important',
        '--c-disabled': 'var(--fitness-walking-primary-light) !important',
      },
      '.balance-text': { 'text-transform': 'initial !important' },
      '.button': { 'box-shadow': 'none !important' },
    };

    const quizVariantStyles = {
      [QUIZ_VARIANTS.BODY_NUTRITION]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: traditionalStyles,
      [QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: traditionalStyles,
      [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: traditionalStyles,
      [QUIZ_VARIANTS.NUTRITION_WEEKLY_START]: traditionalStyles,
      [QUIZ_VARIANTS.NUTRITION_WEEKLY_CORTISOL_CLEANSE]: traditionalStyles,
      [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: traditionalStyles,
      [QUIZ_VARIANTS.MACRO_DIET]: traditionalStyles,
      [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: traditionalStyles,
      [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: traditionalStyles,
      [QUIZ_VARIANTS.METABOLIC_RENEWAL]: traditionalStyles,
      [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: traditionalStyles,
      [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: traditionalStyles,
      [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: traditionalStyles,
      [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: pilatesStyles,
      [QUIZ_VARIANTS.WALL_PILATES]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_NOW]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_NEW_LANDER]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_LANDER]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: pilatesStyles,
      [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: pilatesStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO]: ketoStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: ketoStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: ketoStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: ketoStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: ketoStyles,
      [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: ketoStyles,
      [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: yogaStyles,
      [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: yogaStyles,
      [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: yogaStyles,
      [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: yogaStyles,
      [QUIZ_VARIANTS.SOMATIC_YOGA]: somaticYoga,
      [QUIZ_VARIANTS.SOMATIC_EXERCISES]: somaticYoga,
      [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: traditionalStyles,
      [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: yogaStyles,
      [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: yogaStyles,
      [QUIZ_VARIANTS.WALL_PILATES]: fitOver40Styles,
      [QUIZ_VARIANTS.NUTRITION_WEEKLY_MEDITERRANEAN_DIET]: traditionalStyles,
      [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR]: yogaStyles,
      [QUIZ_VARIANTS.WALKING_INDOOR]: fitnessWalkingStyles,
      [QUIZ_VARIANTS.CHAIR_YOGA_V1]: yogaStyles,
      [QUIZ_VARIANTS.YOGA_LAZY]: yogaStyles,
    };

    const styles = Object.entries(quizVariantStyles[this.getQuizVariant] || {})
      .map(([key, value]) =>
        typeof value === 'object'
          ? `${key} { ${Object.entries(value)
              .map(([prop, val]) => `${prop}: ${val};`)
              .join(' ')} }`
          : `${key}: ${value}`,
      )
      .join(' ');

    return {
      bodyAttrs: {
        class: this.$exp.$classes.join(' '),
      },
      htmlAttrs: {
        lang: this.$i18n.locale.split('-')[0] || 'en',
      },
      style: [
        {
          cssText: styles,
        },
      ],
    };
  },
  watch: {
    $route() {
      if (process.client) {
        if (window) {
          window.scrollTo({ top: 0 });
        }
      }

      this.setAuthComponentName(AUTH_LOGIN);
    },
  },
  mounted() {
    if (window && window.balanceText && typeof window.balanceText === 'function') {
      window.balanceText('.balance-text');
    }

    this.queryHandler();
  },
  methods: {
    ...mapActions('auth', ['setAuthComponentName']),
  },
};

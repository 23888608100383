export const QUIZ_VARIANTS = Object.freeze({
  DEFAULT: '0',
  NUTRITION: '99',
  LEAN_BODY: '121',
  TRIAL: '124',
  BEST_BODY: '129',
  TRIAL_GET_BACK_INTO_SHAPE: '130',
  BODY_28_DAY_CHALLENGE: '132',
  BEST_BODY_OVER_40: '134',
  YOUR_BEST_BODY: '137',
  BEST_LEAN_BODY: '138',
  PILATES_NOW: '139',
  BODY_NUTRITION: '142',
  PILATES_MONTHLY: '144',
  PILATES_28_DAY_CHALLENGE_MONTHLY: '145',
  PILATES_NEW_LANDER: '147',
  WEIGHT_LOSS_MONTHLY: '148',
  WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN: '231',
  PILATES_28_DAY_CHALLENGE_NEW_LANDER: '149',
  NUTRITION_KETO: '150',
  NUTRITION_KETO_MEAL: '205',
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: '155',
  PILATES_LEGAL_DISCLAIMER: '156',
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: '159',
  HEALTH_NO_SUGAR_CHALLENGE: '160',
  NUTRITION_KETO_WEEKLY: '161',
  NUTRITION_KETO_AGE: '162',
  FAST_WEIGHT_LOSS: '164',
  MACRO_DIET: '165',
  OPTIMAL_WEIGHT: '166',
  WEIGHT_LOSS_OVER_40: '167',
  WEIGHT_LOSS_WITHOUT_FASTING: '168',
  HEALTH_FOOD_IMPACT: '169',
  METABOLIC_RENEWAL: '170',
  CHOLESTEROL_CONTROL: '171',
  WEIGHT_LOSS_SECRET: '172',
  YOGA_28_DAY_CHALLENGE: '175',
  PILATES_NEW_CHECKOUT: '179',
  PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT: '180',
  NUTRITION_WEEKLY_START: '187',
  NUTRITION_KETO_WEEKLY_AGE: '188',
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: '203',
  YOGA_MENOPAUSE_CHALLENGE: '210',
  NUTRITION_KETO_MEAL_WEEKLY: '212',
  SOMATIC_YOGA: '219',
  SOMATIC_EXERCISES: '220',
  PILATES_28_DAY_CHALLENGE_BMI_STEP_V1: '227',
  PILATES_28_DAY_CHALLENGE_BMI_STEP_V2: '228',
  PILATES_28_DAY_CHALLENGE_BMI_STEP_V3: '229',
  PILATES_28_DAY_CHALLENGE_BMI_STEP_V4: '230',
  NUTRITION_MONTHLY_GENERIC_US: '232',
  PILATES_28_DAY_CHALLENGE_GENERIC_US: '233',
  YOGA_28_DAY_CHALLENGE_GENERIC_US: '234',
  NUTRITION_MONTHLY_GENERIC_OTHERS: '236',
  PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS: '237',
  YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS: '238',
  PILATES_28_DAY_CHAIR_CHALLENGE: '239',
  PILATES_28_DAY_FLAT_BELLY: '240',
  WALL_PILATES: '242',
  WEIGHT_LOSS_MONTHLY_META_CHANGES: '257',
  NUTRITION_MONTHLY_CORTISOL_CLEANSE: '259',
  NUTRITION_WEEKLY_CORTISOL_CLEANSE: '260',
  YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1: '264',
  YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2: '265',
  NUTRITION_WEEKLY_MEDITERRANEAN_DIET: '266',
  WALKING_INDOOR: '258',
  YOGA_28_DAY_CHALLENGE_SENIOR: '267',
  CHAIR_YOGA_V1: '272',
  PILATES_28_DAY_CHALLENGE_GOAL_STEP: '274',
  PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1: '276',
  PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2: '277',
  PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3: '278',
  YOGA_LAZY: '275',
});

export const LANDING_PAGE_IDS = Object.freeze({
  DEFAULT: 0,
  BEST_BODY: 2,
  BODY_28_DAY_CHALLENGE: 3,
  CHOLESTEROL_CONTROL: 4,
  FAST_WEIGHT_LOSS_FOR_WOMEN: 5,
  LEAN_BODY: 8,
  MACRO_DIET_FOR_WOMEN: 9,
  METABOLIC_RENEWAL_FOR_WOMEN: 12,
  OPTIMAL_WEIGHT_PROGRAM: 13,
  PILATES: 14,
  PILATES_28_DAY_CHALLENGE: 15,
  TRIAL: 16,
  TRIAL_GET_BACK_INTO_SHAPE: 17,
  WEIGHT_LOSS: 19,
  WEIGHT_LOSS_OVER_40: 20,
  WEIGHT_LOSS_SECRET: 21,
  WEIGHT_LOSS_WITHOUT_FASTING: 22,
  WOMENS_HEALTH_FOOD_IMPACT: 24,
  BEST_BODY_OVER_40: 25,
  YOUR_BEST_BODY: 26,
  BEST_LEAN_BODY: 28,
  PILATES_NOW: 29,
  BODY_NUTRITION: 31,
  PILATES_MONTHLY: 32,
  PILATES_28_DAY_CHALLENGE_MONTHLY: 33,
  NUTRITION_KETO: 35,
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: 40,
  PILATES_LEGAL_DISCLAIMER: 41,
  HEALTH_NO_SUGAR_CHALLENGE: 42,
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: 43,
  NUTRITION_KETO_WEEKLY: 44,
  NUTRITION_KETO_AGE: 45,
  NUTRITION_WEEKLY_START: 46,
  YOGA_28_DAY_CHAIR_YOGA_CHALLENGE: 47,
  NUTRITION_KETO_WEEKLY_AGE: 48,
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: 54,
  YOGA_MENOPAUSE_CHALLENGE: 62,
  NUTRITION_KETO_MEAL_WEEKLY: 64,
  NUTRITION_KETO_MEAL: 65,
  SOMATIC_YOGA: 69,
  SOMATIC_EXERCISES: 70,
  NUTRITION_MONTHLY_GENERIC_US: 71,
  NUTRITION_MONTHLY_GENERIC_OTHERS: 72,
  PILATES_28_DAY_CHALLENGE_US: 73,
  PILATES_28_DAY_CHALLENGE_OTHERS: 74,
  YOGA_28_DAY_CHALLENGE_GENERIC_US: 75,
  YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS: 76,
  WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN: 77,
  WALL_PILATES: 78,
  NUTRITION_MONTHLY_CORTISOL_CLEANSE: 81,
  NUTRITION_WEEKLY_CORTISOL_CLEANSE: 82,
  NUTRITION_WEEKLY_MEDITERRANEAN_DIET: 83,
  WALKING_INDOOR: 84,
  YOGA_28_DAY_CHALLENGE_SENIOR: 84,
});
